import useSWR from 'swr'
import { Position } from '../../state/initial'

export interface Skill {
    id: number
    name: string
    validFrom?: Date
    validTo?: Date
}

export interface DetailedPosition extends Position {
    description: string
    imageUrl?: string
    requiredSkills: Skill[]
    isRegular: boolean
}

export const useMySkills = () =>
    useSWR<Skill[]>(`${window.env.REACT_APP_SKILL_SERVICE}/api/skills/my`)

export const useMyPositions = () =>
    useSWR<DetailedPosition[]>(`${window.env.REACT_APP_SKILL_SERVICE}/api/position/my`)
