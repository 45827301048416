import React from 'react'
import { useNavigate, useParams } from 'react-router'
import Document from '../Document'
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams'
import * as API from '../../../../api'
import { Loader } from 'semantic-ui-react'

const DocumentViewPage: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const { documentId } = useParams<{ documentId: string }>()
    const {
        isLoading,
        data,
        refresh
    } = useGetQueryByParams(!!documentId, API.documents.getIdeaById, "documentItem", documentId)

    const onStateInvalidated = () => {
        //Todo fix this without ugly navigation
        refresh()
        navigate('/dummy')
        navigate(-1)
    }

    if (isLoading || !data) {
        return <Loader active={isLoading} />
    }
    return (
        <Document key={data.id} document={data!} onStateInvalid={onStateInvalidated} />
    )
}

export default DocumentViewPage
