import useApi from '../useApi'

interface ResponsiblePositionsDto {
    companyPositions: number[]
    concernPositions: number[]
}

export const useResponsiblePosition = (documentId: string) => {
    const { data, loading, post, mutate, error } = useApi<ResponsiblePositionsDto, number[]>(
        `${window.env.REACT_APP_DOC_SERVICE}/api/document/${documentId}/responsible`,
    )

    return {
        companyPositions: data?.companyPositions ?? [],
        concernPositions: data?.concernPositions ?? [],
        loading,
        update: (positions: number[]) => {
            const mergedPositions = [...positions, ...data?.concernPositions ?? []]
            return post(mergedPositions).then(() =>
                mutate({
                    companyPositions: positions,
                    concernPositions: data?.concernPositions ?? [],
                }),
            )
        },

        error,
    }
}
