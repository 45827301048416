import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import FullHeightStackingContainer from '../../../common/fullHeightStackingContainer'
import { getDepartmentFromState, getLocationFromState, getSupplierFromState, getSupplierOptionsFromState, getUserFromState } from '../../../../state/helpers'
import { getState } from '../../../../state'
import { Button, Checkbox, Header, Popup } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import {
    GroupingState,
    createColumnHelper,
    ColumnDef,
    useReactTable,
    getExpandedRowModel,
    getGroupedRowModel,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    VisibilityState,
    PaginationState,
    ColumnFiltersState,
    SortingState,
    ExpandedState
} from '@tanstack/react-table'
import DateCell from '../../../common/cells/DateCell'
import TableReact from '../../../wrappers/TableReact'
import { issueStatusRowClass } from '../../../wrappers/TableReact/RowClassHelpers'
import { formatDate } from '../../../../actions/util'
import useTableStorageBackedState from '../../../wrappers/TableReact/hooks/useTableStorageBackedState'
import { useIsInRole } from '../../../../hooks/role'
import { AdminRoles } from '../../../../auth/roles'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import { Bell, Edit, Trash2 } from 'react-feather'
import { DepartmentDisplay, SupplierDisplay, LocationDisplay } from '../../../common/globalStateDisplay'
import { EnumMultiFilterSelect, enumMultiFilter } from '../../../wrappers/TableReact/Filters/EnumMultiFilter'
import { globalFilterFn } from '../../../wrappers/TableReact/Filters/GlobalFilter'
import UserNameDisplay from '../../../common/UserNameDisplay'
import { DeviationMethod, IIssueDto, IssueTypeHierarchy, ModuleCollection } from '../../../../api/issues'
import { useDeviationReminder } from './hooks/useDeviationReminder'
import { useDeleteDeviation } from './hooks/useDeleteDeviation'
import { IssueStatus } from '../../../../api/types/Enum'
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams'
import * as API from '../../../../api'
import Loading from '../../../common/Loading'
import { MultiDropdownFilter, multiFilter } from '../../../wrappers/TableReact/Filters/MultiDropdownFilter'
import { useUserOptionsOverride } from '../../../../hooks/users'
import { BooleanFilter, booleanFilter } from '../../../wrappers/TableReact/Filters/BooleanFilter'
import { DateRangeFilter, dateBetweenFilterFn } from '../../../wrappers/TableReact/Filters/DateRangeFilter'
import EmptyRequiredCollection from '../../../common/EmptyRequiredCollection'
import ExtraFieldCell from '../../../common/cells/ExtraFieldCell'
import MultiUserCell from '../../../common/cells/MultiUserCell'
import { isArray } from 'lodash'

interface IIssueListProps {
    issues: IIssueDto[]
    types: IssueTypeHierarchy[]
    refresh: () => void
}
const defaultHiddenColumns: { [x: string]: boolean; } = {
    "supplierId": false,
    "time": false,
    "cost": false,
    "supplierShouldReply": false,
    "sent": false,
    "replied": false,
    "errorCode": false,
    "causeCode": false,
    "locationId": false,
    "description": false,
    "analysis": false,
    "actionsTaken": false,
    "endedBy": false,
    "useReview": false,
    "reviewResponsibleId": false,
    "reviewDate": false,
    "reviewText": false,
    "extraInformation1": false,
    "extraInformation2": false,
    "resolvingAction": false,
    "teamMembers": false,
    "desiredEffect": false,
    "desiredEffectComment": false,
}

const IssueList = ({ issues, types, refresh }: IIssueListProps) => {
    const { t } = useTranslation()
    const { state } = getState()
    const navigate = useNavigate()
    const [columnVisibility, setColumnVisibility] = useTableStorageBackedState<VisibilityState>('issueVisibilityState', defaultHiddenColumns);
    const [grouping, setGrouping] = useTableStorageBackedState<GroupingState>('issueGroupingVisibility', []);
    const [pagination, setPagination] = useTableStorageBackedState<PaginationState>('issuePaginationState', { pageSize: 10, pageIndex: 0 });
    const [showAll, setShowAll] = useTableStorageBackedState('issueListShowAllState', false);
    const [columnFilters, setColumnFilters] = useTableStorageBackedState<ColumnFiltersState>('issueFilteringState', []);
    const [globalFilter, setGlobalFilter] = useTableStorageBackedState('issueGlobalFilterState', "");
    const [sorting, setSorting] = useTableStorageBackedState<SortingState>('issueSortingState', [{ id: "status", desc: false }, { id: "doneBefore", desc: false }]);
    const [expanded, setExpanded] = useTableStorageBackedState<ExpandedState>("issueExpandedState", {})


    const { isInRole: isAdmin } = useIsInRole(AdminRoles.IssuesAdminRole)

    const columnHelper = createColumnHelper<IIssueDto>()
    const {
        isLoading: isLoadingSettings,
        data: settings,
    } = useGetQueryByParams(true, API.issues.getExtraFields, "deviationSettings", ModuleCollection.Deviations)

    const hasLocations = state.locations?.length
    const hasSuppliers = state.suppliers?.length
    const supplierOptions = getSupplierOptionsFromState(state)(t)

    const { isSending, onNotify } = useDeviationReminder()
    const { isDestroying, onDestroy } = useDeleteDeviation(refresh)
    const userOptions = useUserOptionsOverride(t)

    const issueColumns: ColumnDef<IIssueDto, any>[] = useMemo(
        () => [
            columnHelper.display({
                id: "actions",
                enableHiding: false,
                enableGrouping: false,
                size: 140,
                cell: ({ row }) =>
                    <>
                        <Button title={t('edit')} type='button' onClick={() => navigate(`./detail/${row.original.id}`)} icon={<SemanticFeatherIcon FeatherIcon={Edit} />} />
                        <Popup
                            content={!getUserFromState(state)(row.original.responsibleId)?.active ? t('disabledResponsibleReminder') : t('sendReminder')}
                            trigger={
                                <span>
                                    <Button type='button'
                                        onClick={() => onNotify({ id: row.original.id, status: row.original.status })}
                                        icon={<SemanticFeatherIcon FeatherIcon={Bell} />}
                                        disabled={!getUserFromState(state)(row.original.responsibleId)?.active}
                                        loading={isSending} />
                                </span>
                            }
                        />
                        {isAdmin && <Button
                            title={t('delete')}
                            disabled={isDestroying}
                            onClick={() => onDestroy({ confirmText: t('confirm.deleteValue', { value: row.original.topic }), id: row.original.id })}
                            type='button'
                            color='red'
                            icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />} />}
                    </>
            }),
            columnHelper.accessor((r) => r.ownId.toString(), {
                id: "ownId",
                header: t('ownId'),
                size: 60,
            }),
            columnHelper.accessor((r) => t(`issueStatus.${r.status}`), {
                id: "status",
                header: t('status'),
                filterFn: enumMultiFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: string | IssueStatus[]) => void, value: IssueStatus[]) => (
                        <EnumMultiFilterSelect
                            setFilterValue={setFilterValue}
                            value={value}
                            Enum={IssueStatus}
                            enumLangKey="issueStatus"
                        />
                    ),
                },
                size: 80,
                cell: ({ row }) => <span>{t(`issueStatus.${row.original.status}`)}</span>
            }),
            columnHelper.accessor((r) => t(`deviationMethod.${r.deviationMethod}`), {
                id: "deviationMethod",
                header: t('deviationMethod'),
                filterFn: enumMultiFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: string | DeviationMethod[]) => void, value: DeviationMethod[]) => (
                        <EnumMultiFilterSelect
                            setFilterValue={setFilterValue}
                            value={value}
                            Enum={DeviationMethod}
                            enumLangKey="deviationMethod"
                        />
                    ),
                },
                cell: ({ row }) => <span>{t(`deviationMethod.${row.original.deviationMethod}`)}</span>
            }),
            columnHelper.accessor('typeName', {
                id: "typeName",
                header: t('type'),
            }),
            columnHelper.accessor((r) => r.errorName ?? "", {
                id: "errorCode",
                header: t('errorCode'),
            }),
            columnHelper.accessor((r) => r.causeName ?? "", {
                id: "causeCode",
                header: t('causeCode'),
            }),
            columnHelper.accessor("topic", {
                id: "topic",
                header: t('topic'),
                size: 300,
            }),
            columnHelper.accessor((r) => getUserFromState(state)(r.responsibleId)?.name ?? "", {
                id: "responsibleId",
                header: t('responsibleId'),
                filterFn: multiFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: string) => void, value: string[]) => (
                        <MultiDropdownFilter
                            setFilterValue={setFilterValue}
                            value={value}
                            options={userOptions}

                        />
                    ),
                },
                cell: ({ row }) => <UserNameDisplay
                    userId={row.original.responsibleId ?? undefined}
                    fallbackValue={t('userFromAnotherCompany')}
                />
            }),
            columnHelper.accessor((r) => r.teamMembers.map(id => getUserFromState(state)(id)?.name ?? '').join(', '), {
                id: "teamMembers",
                header: t('teamMembers'),
                cell: ({ row }) => <MultiUserCell
                    users={row.original.teamMembers}
                />
            }),
            columnHelper.accessor("desiredEffect", {
                id: "desiredEffect",
                header: t('desiredEffect'),
                filterFn: booleanFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: boolean) => void, value: boolean) => (
                        <BooleanFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                    checkbox: true
                },
                cell: ({ row }) => <Checkbox checked={!!row.original.desiredEffect} />
            }),
            columnHelper.accessor("desiredEffectComment", {
                id: "desiredEffectComment",
                header: t('desiredEffectComment'),
            }),
            columnHelper.accessor((r) => r.departmentId ? getDepartmentFromState(state)(r.departmentId)?.name : "", {
                id: "departmentId",
                header: t('department'),
                filterFn: multiFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: string) => void, value: string[]) => (
                        <MultiDropdownFilter
                            setFilterValue={setFilterValue}
                            value={value}
                            options={state.departments.map(d => ({ value: d.id, text: d.name })) ?? []}
                        />
                    ),
                },
                cell: ({ row }) => <DepartmentDisplay departmentId={row.original.departmentId} />
            }),
            ...(
                hasLocations
                    ? [columnHelper.accessor((r) => r.locationId ? getLocationFromState(state)(r.locationId)?.name : "", {
                        id: "locationId",
                        header: `${t('locationId')}`,
                        filterFn: multiFilter,
                        meta: {
                            filterComponent: (setFilterValue: (updater: string) => void, value: string[]) => (
                                <MultiDropdownFilter
                                    setFilterValue={setFilterValue}
                                    value={value}
                                    options={state.locations?.map(d => ({ value: d.id, text: d.name })) ?? []}
                                />
                            ),
                        },
                        cell: ({ row }) => <LocationDisplay locationId={row.original.locationId} />
                    }),
                    ]
                    : []
            ),
            ...(
                settings && settings.extraFields
                    ? Object.keys(settings.extraFields).map(key => {
                        const field = settings.extraFields[key];
                        return columnHelper.accessor(r => r.extraFields && r.extraFields[key] ? field.type === 4 && isArray(r.extraFields[key]) ?
                            (r.extraFields[key] as string[]).map((id: any) => getUserFromState(state)(id)?.name ?? '')
                                .join(', ') : r.extraFields[key]
                            : "", {
                            id: key,
                            header: key,
                            cell: ({ row }) => <ExtraFieldCell field={field} value={row.original.extraFields ? row.original.extraFields[key] : ""} />
                        })
                    })
                    : []
            ),
            columnHelper.accessor(r => r.doneBefore ? formatDate(r.doneBefore) : "", {
                id: "doneBefore",
                header: t('doneBefore'),
                filterFn: dateBetweenFilterFn,
                meta: {
                    filterComponent: (setFilterValue: (updater: any) => void, value: Date[]) => (
                        <DateRangeFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                },
                cell: ({ row }) => <DateCell date={row.original.doneBefore} />
            }),
            ...(
                hasSuppliers
                    ? [columnHelper.accessor((r) => r.supplierId ? getSupplierFromState(state)(r.supplierId)?.name ?? "" : "", {
                        id: "supplierId",
                        header: t('supplierId') as any,
                        filterFn: multiFilter,
                        meta: {
                            filterComponent: (setFilterValue: (updater: string) => void, value: string[]) => (
                                <MultiDropdownFilter
                                    setFilterValue={setFilterValue}
                                    value={value}
                                    options={supplierOptions}
                                />
                            ),
                        },
                        cell: ({ row }) => <SupplierDisplay supplierId={row.original.supplierId} />
                    }),
                    ]
                    : []
            ),

            columnHelper.accessor('customerSupplier', {
                id: "customerSupplier",
                header: t('customerSupplier'),

            }),
            columnHelper.accessor((r) => r.time ? r.time.toString() : "", {
                id: "time",
                header: t('time'),
            }),
            columnHelper.accessor((r) => r.cost ? r.cost.toString() : "", {
                id: "cost",
                header: t('cost'),
            }),
            columnHelper.accessor((r) => getUserFromState(state)(r.reportedBy)?.name ?? "", {
                id: "reportedBy",
                header: t('reportedBy'),
                filterFn: multiFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: string) => void, value: string[]) => (
                        <MultiDropdownFilter
                            setFilterValue={setFilterValue}
                            value={value}
                            options={userOptions}

                        />
                    ),
                },
                cell: ({ row }) => <UserNameDisplay
                    userId={row.original.reportedBy ?? undefined}
                    fallbackValue={t('userFromAnotherCompany')}
                />
            }),
            columnHelper.accessor(r => r.reportDate ? formatDate(r.reportDate) : "", {
                id: "reportDate",
                header: t('reportDate'),
                filterFn: dateBetweenFilterFn,
                meta: {
                    filterComponent: (setFilterValue: (updater: any) => void, value: Date[]) => (
                        <DateRangeFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                },
                cell: ({ row }) => <DateCell date={row.original.reportDate} />
            }),
            columnHelper.accessor("description", {
                id: "description",
                header: t('description'),
            }),
            columnHelper.accessor("actionsTaken", {
                id: "actionsTaken",
                header: t('actionsTaken'),
            }),
            columnHelper.accessor("analysis", {
                id: "analysis",
                header: t('analysis'),
            }),
            columnHelper.accessor("resolvingAction", {
                id: "resolvingAction",
                header: t('resolvingAction'),
            }),
            columnHelper.accessor("endedBy", {
                id: "endedBy",
                header: t('endedBy'),
            }),
            columnHelper.accessor(r => r.endedDate ? formatDate(r.endedDate) : "", {
                id: "endedDate",
                header: t('endedDate'),
                filterFn: dateBetweenFilterFn,
                meta: {
                    filterComponent: (setFilterValue: (updater: any) => void, value: Date[]) => (
                        <DateRangeFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                },
                cell: ({ row }) => <DateCell date={row.original.endedDate} />
            }),
            columnHelper.accessor("useReview", {
                id: "useReview",
                header: t('useReview'),
                filterFn: booleanFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: boolean) => void, value: boolean) => (
                        <BooleanFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                    checkbox: true
                },
                cell: ({ row }) => <Checkbox checked={!!row.original.useReview} />
            }),
            columnHelper.accessor(r => r.reviewBeforeDate ? formatDate(r.reviewBeforeDate) : "", {
                id: "reviewBeforeDate",
                header: t('reviewBeforeDate'),
                cell: ({ row }) => <DateCell date={row.original.reviewBeforeDate} />
            }),
            columnHelper.accessor("reviewText", {
                id: "reviewText",
                header: t('reviewText'),
            }),
            columnHelper.accessor((r) => getUserFromState(state)(r.reviewResponsibleId)?.name ?? "", {
                id: "reviewResponsibleId",
                header: t('reviewResponsibleId'),
                filterFn: multiFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: string) => void, value: string[]) => (
                        <MultiDropdownFilter
                            setFilterValue={setFilterValue}
                            value={value}
                            options={userOptions}

                        />
                    ),
                },
                cell: ({ row }) => <UserNameDisplay
                    userId={row.original.reviewResponsibleId ?? undefined}
                    fallbackValue={t('userFromAnotherCompany')}
                />
            }),
            columnHelper.accessor(r => r.reviewDate ? formatDate(r.reviewDate) : "", {
                id: "reviewDate",
                header: t('reviewDate'),
                cell: ({ row }) => <DateCell date={row.original.reviewDate} />
            }),
            columnHelper.accessor("supplierShouldReply", {
                id: "supplierShouldReply",
                header: t('supplierShouldReply'),
                filterFn: booleanFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: boolean) => void, value: boolean) => (
                        <BooleanFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                    checkbox: true
                },
                cell: ({ row }) => <Checkbox checked={!!row.original.supplierShouldReply} />
            }),
            columnHelper.accessor(r => r.sent ? formatDate(r.sent) : "", {
                id: "sent",
                header: t('sent'),
                cell: ({ row }) => <DateCell date={row.original.sent} />
            }),
            columnHelper.accessor("replied", {
                id: "replied",
                header: t('replied'),
                filterFn: booleanFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: boolean) => void, value: boolean) => (
                        <BooleanFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                    checkbox: true
                },
                cell: ({ row }) => <Checkbox checked={!!row.original.replied} />
            }),
        ], [columnHelper, navigate, state, t, isAdmin, hasLocations, onNotify, onDestroy, isDestroying, isSending,
        settings, userOptions, hasSuppliers])

    const table = useReactTable({
        data: issues || [],
        columns: issueColumns.filter(x => !hasLocations ? x.id !== "locationId" : x),
        columnResizeMode: "onChange",
        state: {
            columnVisibility,
            grouping,
            pagination,
            columnFilters,
            globalFilter,
            sorting,
            expanded
        },
        initialState: {
            globalFilter: "",
            columnFilters: [{ id: "status", value: [IssueStatus.Active, IssueStatus.Evaluate] }],
            grouping: [],
            sorting: [{ id: "status", desc: false }, { id: "doneBefore", desc: false }],
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: globalFilterFn,
        onExpandedChange: setExpanded,
        onGroupingChange: setGrouping,
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })
    if (isLoadingSettings) {
        return <Loading />
    }
    return (
        <FullHeightStackingContainer>
            <Header dividing>{t('issues')}</Header>
            <EmptyRequiredCollection types={types} />
            <TableReact table={table}
                onAddCallback={() => navigate("./detail/new")}
                rowClassRenderer={(r) => issueStatusRowClass(r)}
                canExport
                showAllFilter={{ showAll, setShowAll, checked: [{ id: "status", value: [] }], notChecked: [{ id: "status", value: [IssueStatus.Active, IssueStatus.Evaluate] }] }} />
        </FullHeightStackingContainer>
    )
}

export default IssueList

